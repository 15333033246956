function App() {
  return (
  <>

	<div className="page-wraper">
		<header className="site-header mo-left header header-transparent">
			<div className="sticky-header main-bar-wraper navbar-expand-lg">
				<div className="main-bar clearfix ">
					<div className="container clearfix">

						
						<div className="logo-header logo-dark">
							<a href="https://bervice.co/"><img src="assets/images/logo.png" alt=""/></a>
						</div>

				
						<button className="navbar-toggler collapsed navicon justify-content-end" type="button"
							data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
							aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
							<span></span>
							<span></span>
							<span></span>
						</button>

						<div className="header-nav navbar-collapse collapse " id="navbarNavDropdown">
							<div className="logo-header logo-dark">
								<a href="https://bervice.co/"><img src="assets/images/logo.png" alt=""/></a>
							</div>
							<ul className="nav navbar-nav navbar navbar-left">
								<li><a href="#menu_why">Why</a></li>
								<li><a href="#menu_setup">Setup</a></li>
								<li><a href="#menu_about">About Us</a></li>
								<li className="sub-menu-down"><a href="/">Contact</a>
									<ul className="sub-menu">
										<li><a href="https://www.linkedin.com/in/bervice/">linkedin</a></li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
	
		</header>
	

		<div className="page-content">

			<div className="main-slider style-1 position-relative">
				
				<div className="main-swiper swiper">
					<div className="swiper-wrapper">
						<div className="swiper-slide" data-rel="1">
							<div className="main-bnr">
								<div className="container">
									<div className="row bnr-row">
										<div className="col-xl-7 col-lg-7 col-md-6 col-sm-7">
										<h6 className="sub-title wow fadeInUp" data-wow-delay="0.1s"
												data-swiper-parallax="-50">.</h6>
											<h1 className="wow fadeInUp" data-wow-delay="0.2s" data-swiper-parallax="-50">
											BERVICE WALLET</h1>
											
												<p>Safeguarding Your Assets, One Block at a Time</p>
												<p>Protection of your cryptocurrency assets against hacking and theft on the blockchain</p>
											<a className="btn btn-primary btn-lg wow fadeInUp" data-wow-delay="0.3s"
												data-swiper-parallax="-50" href="https://bervice.co/">
												Launch App
												<i className="fa-solid fa-circle-arrow-right"></i>
											</a>
										</div>
										
										<div className="col-xl-5 col-lg-5 col-md-6 col-sm-5">
											<div className="main-media">
												<img className="media" src="assets/images/home-banner/ab.png" alt=""/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>


					
					{/* <div className="container">
						<div className="banner-bottom">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-8 m-b10">
									<div className="widget-box style-1" style={{borderRadius:'15px'}}>
										
										<div className="dz-info">
											<h6 className="title"><a href="https://bervice.co/">How bervice wallet works
											</a>
											
											</h6>
											
											<div className="bottom-content">
												<span className="size">Medium</span>
												<h6 className="price">Read</h6>
											</div>
										</div>
									</div>
								</div>
								
								<div className="col-lg-6 col-md-6 col-sm-8 m-b10">
									<div className="widget-box style-1" style={{borderRadius:'15px'}}>
										
										<div className="dz-info">
											<h6 className="title"><a href="https://bervice.co/">Setup your Bodygaurd</a>
											</h6>
											
											<div className="bottom-content">
												<span className="size">Medium</span>
												<h6 className="price">Read</h6>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div> */}

				</div>

				<ul className="banner-shape">
					<li></li>
					<li></li>
					<li></li>
					<li></li>
				</ul>
			</div>
	

			<section className="content-inner-1" id='menu_why'>
				<div className="container">
					<div className="section-head text-center">
						<p className="text wow fadeInUp" data-wow-delay="0.1s">Why do we need to use bervice?</p>
						<h2 className="title wow fadeInUp" data-wow-delay="0.2s">Why bervice ?</h2>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-4 col-md-6 m-b30">
							<div className="icon-bx-wraper style-1 box-hover text-center wow fadeInUp"
								data-wow-delay="0.1s">
								<div className="icon-media">
									<img src="assets/images/icon/pic1.png" alt="Why bervice ?"/>
								</div>
								<div className="icon-content">
									<h5 className="title">Enhanced Security with Smart Contracts</h5>
									<p className="text">Secure Escrow Services-Trustless Interactions-In the event of the leakage of your private key, your assets will be safeguarded</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 m-b30">
							<div className="icon-bx-wraper style-1 box-hover  active text-center wow fadeInUp center"
								data-wow-delay="0.2s">
								<div className="icon-media">
									<img src="assets/images/icon/pic2.png" alt="Why bervice ?"/>
								</div>
								<div className="icon-content">
									<h5 className="title">Suspicious Activity Warnings</h5>
									<p className="text">Our alerting system is vigilant against any unusual or suspicious activities within your account, providing an additional layer of security.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 m-b30">
							<div className="icon-bx-wraper style-1 box-hover  text-center wow fadeInUp"
								data-wow-delay="0.3s">
								<div className="icon-media">
									<img src="assets/images/icon/pic3.png" alt="Why bervice ?"/>
								</div>
								<div className="icon-content">
									<h5 className="title">Safe Transfer Assets System</h5>
									<p className="text">Our Safe Transfer Assets System is engineered to provide you with a seamless and secure transfer experience, ensuring your digital wealth is protected every step of the way.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="product-wrapper1 content-inner-1" id='menu_setup'>
				<div className="container">
					<div className="row section-head align-items-center">
						<div className="text-center text-xl-start col-xl-7 m-lg-b20">
							<p className="text wow fadeInUp" data-wow-delay="0.1s">Bervice Wallet</p>
							<h2 className="title wow fadeInUp" data-wow-delay="0.2s">Embark on Your Journey</h2>
						</div>
						
					</div>
					<div className="row">
						<div className="col-xl-3 col-sm-6">
							<div className="product-bx wow fadeInUp" data-wow-delay="0.3s">
								
								<div className="product-content">
									<h5 className="title "><a href="https://bervice.co/">1. Download and Install Bervice App</a></h5>
									
									
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-sm-6">
							<div className="product-bx wow fadeInUp" data-wow-delay="0.4s">
								
								<div className="product-content">
									<h5 className="title "><a href="https://bervice.co/">2. Generate Bodyguard Wallet</a></h5>
									
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-sm-6">
							<div className="product-bx wow fadeInUp" data-wow-delay="0.5s">
							
								<div className="product-content">
									<h5 className="title "><a href="https://bervice.co/">3. Transfer Assets to Your Wallet</a></h5>
									
									
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-sm-6">
							<div className="product-bx wow fadeInUp" data-wow-delay="0.6s">
								
								<div className="product-content">
									<h5 className="title "><a href="https://bervice.co/">4. Active Your Services</a></h5>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="content-inner overflow-hidden about-section" id='menu_about'>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-6 col-sm-6 m-b30">
							<div className="about-media">
								<svg className="shap-1" viewBox="0 0 917 588" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M883.652 165.651L-28 0.330566V587.331L854.966 365.127C869.165 361.554 880.277 350.509 883.935 336.332L915.245 215.004C921.053 192.498 906.522 169.798 883.652 165.651Z"
										fill="var(--primary)" />
								</svg>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 m-b30">
							<div className="about-contant">
								<h2 className="title wow fadeInUp" data-wow-delay="0.2s">Safeguard Your Wealth</h2>
								<p className="wow fadeInUp m-b30" data-wow-delay="0.3s">Ensure comprehensive protection for your digital assets, shielding them against any potential threats or unauthorized access</p>
								
							</div>
						</div>
					</div>

					<div className="row align-items-center about-row-2">
						<div className="col-lg-6 col-md-6 col-sm-6 m-b30">
							<div className="about-contant">
								<h2 className="title wow fadeInUp" data-wow-delay="0.1s">Fortify Your Wallet's Security</h2>
								<p className="wow fadeInUp m-b30" data-wow-delay="0.2s">With our Asset Protector, you gain the ability to vigilantly monitor your assets around the clock, providing peace of mind and security for your digital wealth</p>
							
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 m-b30">
							<div className="about-media right text-lg-end">
								<svg className="shap-2" viewBox="0 0 903 588" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M33.3484 165.82L945 0.5V587.5L62.0341 365.297C47.8353 361.723 36.7234 350.678 33.0648 336.501L1.75452 215.174C-4.05347 192.668 10.4783 169.968 33.3484 165.82Z"
										fill="var(--primary)" />
								</svg>
							</div>
						</div>
					</div>

					<div className="row align-items-center about-row-3">
						<div className="col-lg-6 col-md-6 col-sm-6 m-b30">
							<div className="about-media">
								<svg className="shap-3" viewBox="0 0 917 588" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M883.652 165.651L-28 0.330566V587.331L854.966 365.127C869.165 361.554 880.277 350.509 883.935 336.332L915.245 215.004C921.053 192.498 906.522 169.798 883.652 165.651Z"
										fill="var(--primary)" />
								</svg>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 m-b30">
							<div className="about-contant">
								<h2 className="title wow fadeInUp" data-wow-delay="0.2s">Password Passphrase</h2>
								<p className="wow fadeInUp m-b30" data-wow-delay="0.3s">In the event of a password leak, our unique passphrase feature ensures that your assets remain secure, giving you the valuable time needed to safeguard your property.</p>
							</div>
						</div>
					</div>

				</div>
			</section>

			{/* <section className="product-wrapper1 content-inner-1 overflow-hidden">
				<div className="container">
					<div className="row section-head align-items-center">
						<div className="text-center text-xl-start col-xl-7 m-lg-b20">
							<p className="text wow fadeInUp" data-wow-delay="0.1s">Bervice News</p>
							<h2 className="title wow fadeInUp" data-wow-delay="0.2s">Latest Crypto News</h2>
						</div>
						<div className="text-center text-xl-end col-xl-5">
							<div className="swiper-btn">
								<div className="product-swiper-prev btn-prev style-1"><i
										className="fa-solid fa-arrow-left-long"></i></div>
								<div className="product-swiper-next btn-next style-1"><i
										className="fa-solid fa-arrow-right-long"></i></div>
							</div>
						</div>
					</div>
					<div className="swiper product-swiper swiper-btn-center-lr product-swiper-wrapper m-b30">
						<div className="swiper-wrapper">
							<div className="swiper-slide">
								<div className="dz-img-box style-1 wow fadeInUp" data-wow-delay="0.1s">
									<div className="dz-media">
										<img src="assets/images/gallery/pic2.jpg" alt=""/>
									</div>
									<span className="dz-tag">TOP SELLER</span>
									<div className="dz-content">
										<h5 className="dz-title"><a href="our-menu.html">Pasta</a></h5>
										<p>Lorem ipsum dolor sit amet, dipiscing elit, sed</p>
									</div>
									<div className="dz-hover-content">
										<div className="dz-info">
											<h5 className="dz-title mb-0"><a href="our-menu.html">Pasta</a></h5>
											<span className="dz-price">$55.0</span>
										</div>
										<a href="cart.html" className="btn btn-primary "><i
												className="fa-solid fa-cart-shopping"></i></a>
									</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="dz-img-box style-1 wow fadeInUp" data-wow-delay="0.2s">
									<div className="dz-media">
										<img src="assets/images/gallery/pic5.jpg" alt=""/>
									</div>
									<span className="dz-tag">TOP SELLER</span>
									<div className="dz-content">
										<h5 className="dz-title"><a href="our-menu.html">Oreo Shake</a></h5>
										<p>Lorem ipsum dolor sit amet, dipiscing elit, sed</p>
									</div>
									<div className="dz-hover-content">
										<div className="dz-info">
											<h5 className="dz-title mb-0"><a href="our-menu.html">Shake</a></h5>
											<span className="dz-price">$55.0</span>
										</div>
										<a href="cart.html" className="btn btn-primary "><i
												className="fa-solid fa-cart-shopping"></i></a>
									</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="dz-img-box style-1 wow fadeInUp" data-wow-delay="0.3s">
									<div className="dz-media">
										<img src="assets/images/gallery/pic4.jpg" alt=""/>
									</div>
									<span className="dz-tag">TOP SELLER</span>
									<div className="dz-content">
										<h5 className="dz-title"><a href="our-menu.html">Dal Fry</a></h5>
										<p>Lorem ipsum dolor sit amet, dipiscing elit, sed</p>
									</div>
									<div className="dz-hover-content">
										<div className="dz-info">
											<h5 className="dz-title mb-0"><a href="our-menu.html">Dal</a></h5>
											<span className="dz-price">$55.0</span>
										</div>
										<a href="cart.html" className="btn btn-primary "><i
												className="fa-solid fa-cart-shopping"></i></a>
									</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="dz-img-box style-1 wow fadeInUp" data-wow-delay="0.4s">
									<div className="dz-media">
										<img src="assets/images/gallery/pic6.jpg" alt=""/>
									</div>
									<span className="dz-tag">TOP SELLER</span>
									<div className="dz-content">
										<h5 className="dz-title"><a href="our-menu.html">Pizza</a></h5>
										<p>Lorem ipsum dolor sit amet, dipiscing elit, sed</p>
									</div>
									<div className="dz-hover-content">
										<div className="dz-info">
											<h5 className="dz-title mb-0"><a href="our-menu.html">Pizza</a></h5>
											<span className="dz-price">$55.0</span>
										</div>
										<a href="cart.html" className="btn btn-primary "><i
												className="fa-solid fa-cart-shopping"></i></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}


		</div>


		<footer className="site-footer style-1">
			<div className="footer-bottom">
				<div className="container">
					<div className="footer-inner">
						<div className="row align-items-center">
						<div className="col-lg-6 col-md-12 col-sm-12">
								<div className="widget wow fadeInUp" data-wow-delay="0.1s">
									<div className="footer-logo logo-light">
										<a href="https://bervice.co/"><img src="assets/images/logo-white.png" alt=""/></a>
									</div>
									<p>bervice wallet | blockchain services</p>
								</div>
							</div>
							<div className="col-lg-6 col-md-12 col-sm-12">
							<div className="widget widget_links wow fadeInUp text-lg-end " data-wow-delay="0.4s">
								
								<ul className="justify-content-lg-end">
									<li><a href="/">FAQ</a></li>
									<li><a href="/">Why Bervice</a></li>
									<li><a href="/">Privacy Policy</a></li>
									<li><a href="/">Terms & Condition</a></li>
									<li><a href="/">Support</a></li>
								</ul>
							</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 text-lg-start text-md-start text-center">
								<p className="copyright-text wow fadeInUp" data-wow-delay="0.5s">Copyright 2024 All right
									reserved.</p>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 text-lg-end text-md-end text-center">
								<p className="copyright-text wow fadeInUp" data-wow-delay="0.6s">Powered by <a
										className="text-primary" href="https://bervice.co/" rel="noreferrer"  target="_blank">
										bervice </a></p>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</footer>
	

		<button className="scroltop icon-up" type="button"><i className="fas fa-arrow-up"></i></button>

	</div>
    </>
  );
}

export default App;
